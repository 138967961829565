<template>
  <div class="container">
    <b-row>
      <b-col lg="7" md="12" sm="12">
        <h2 class="p-2 my-2 col-sm-6"><b-icon-person-fill></b-icon-person-fill> Players</h2>
      </b-col>
      <b-col lg="3" md="9" sm="12">
        <b-input-group class="mt-3 col-sm-6">
          <b-form-input type="search" @keyup.enter="enterEnter()" placeholder="Search ..." v-model="svalue"></b-form-input>
          <b-input-group-append>
            <b-button variant="dark" type="search" ref="search" @click="search()"><b-icon-search></b-icon-search></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col lg="2" md="3" sm="12" class="text-center">
        <ModalAdd @reload="getPlayerPaginati(1, null)" />
      </b-col>
    </b-row>
    <b-table responsive id="userTable" hover :fields="fields" :items="items">
      <template #cell(id)="data">
        <div class="d-flex align-items-center">
          <div class="mx-2">
            <img class="rounded" width="60" :src="data.item.image_link" />
          </div>
          <div class="data">
            {{ data.item.name }}<br />
            <b
              >ID: <a :href="'https://steamcommunity.com/profiles/' + data.item.id" target="_blank">{{ data.item.id }}</a></b
            ><br />
            <b>Created on: </b>{{ data.item.created_on == null ? "unknown" : timestampToDateTime(data.item.created_on) }}<br />
            <b>Country:</b> {{ data.item.country == "-" ? "?" : data.item.country }} <img class="flag" width="30" :src="flagImage(data.item.country)" /><br />
          </div>
        </div>
      </template>
      <template #cell(cheatType)="data">
        <span class="hack wh" v-if="data.item.wall_hack == '1'">Wall Hack</span><br v-if="data.item.wall_hack == '1'" />
        <span class="hack ah" v-if="data.item.aim_hack == '1'">Aim Hack</span><br v-if="data.item.aim_hack == '1'" />
        <span class="hack oh" v-if="data.item.other_hacks == '1'">Other Hacks</span>
      </template>
      <template #cell(status)="data">
        <span class="hack vb" v-if="data.item.vac_ban == '1'" v-b-tooltip.hover :title="timestampToDateTime(data.item.ban_date)">Vac Ban</span><br v-if="data.item.vac_ban == '1'" />
        <span class="hack gb" v-if="data.item.game_ban == '1'" v-b-tooltip.hover :title="timestampToDateTime(data.item.ban_date)">Game Ban</span><br v-if="data.item.game_ban == '1'" />
        <span v-if="data.item.game_ban == '1' || data.item.vac_ban == '1'"
          >{{ dateDiff(data.item.report_date, data.item.ban_date) }} {{ dateDiff(data.item.report_date, data.item.ban_date) == 1 ? "day" : "days" }}
        </span>
      </template>
      <template #cell(map)="data">
        <div class="map">
          <img width="150" height="85" class="map_image" :src="data.item.map_image" />
          <span class="map_name">{{ data.item.map_name }}</span>
        </div>
      </template>
    </b-table>
    <b-pagination :value="currPage" variant="dark" pills @change="setCurrPage" :total-rows="totalRows" per-page="10" aria-controls="userTable"></b-pagination>
    Showing {{ (currPage - 1) * 10 + 1 }} to {{ totalRows > currPage * 10 ? currPage * 10 : totalRows }} of {{ totalRows }} entries
  </div>
</template>

<script>
import SteamService from "../services/SteamService";
import ModalAdd from "../components/AddModal";

//const mongo = require("../mongodb");
export default {
  name: "App",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "report_date",
          label: "Report Date",
          formatter: (value) => {
            return this.timestampToDate(value);
          },
          tdClass: "middle",
        },
        { key: "id", label: "Player Profile" },
        { key: "cheatType", label: "Cheat Type", tdClass: "middle" },
        {
          key: "status",
          label: "Status",
          tdClass: "middle text-center",
        },
        { key: "map", label: "Map" },
        {
          key: "last_check",
          label: "Last Check",
          formatter: (value) => {
            return this.timestampToDateTime(value);
          },
          tdClass: "middle",
        },
        { key: "notes", label: "Notes", tdClass: "notes middle" },
      ],
      currPage: 1,
      totalRows: 0,
      svalue: null,
    };
  },

  created: function() {
    document.title = "Home";
  },

  mounted: function() {
    this.getPlayerPaginati(1, null);
  },

  computed: {},

  methods: {
    enterEnter() {
      this.$refs.search.click();
    },
    dateDiff(d1, d2) {
      let date1 = new Date(d1);
      let date2 = new Date(d2);
      let timediff = date2.getTime() - date1.getTime();
      let daydiff = timediff / (1000 * 3600 * 24);
      return Math.floor(daydiff);
    },
    getPlayerPaginati(page, search) {
      this.$emit("startLoad", true);
      SteamService.getPlayers(page, search).then((res) => {
        this.items = res.players;
        this.totalRows = res.rows;
        this.$emit("startLoad", false);
      });
    },

    search() {
      this.$emit("startLoad", true);
      SteamService.getPlayers(1, this.svalue).then((res) => {
        this.items = res.players;
        this.totalRows = res.rows;
        this.$emit("startLoad", false);
      });
    },

    setCurrPage(page) {
      this.getPlayerPaginati(page, this.svalue);
      this.currPage = page;
    },

    flagImage(flag) {
      flag = flag.toLowerCase();
      return "https://steambanchecker.altervista.org/static/img/flags/" + flag + ".svg";
    },
    unixToDateTime: (value) => {
      let a = new Date(value * 1000);
      let year = a.getFullYear();
      let month = a.getMonth() < 10 ? "0" + a.getMonth() : a.getMonth();
      let date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
      let hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
      let min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
      let sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds();
      let formattedTime = date + "/" + month + "/" + year + " " + hour + ":" + min + ":" + sec;
      return formattedTime;
    },
    unixToDate: (value) => {
      let a = new Date(value * 1000);
      let year = a.getFullYear();
      let month = a.getMonth() < 10 ? "0" + a.getMonth() : a.getMonth();
      let date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
      let formattedTime = date + "/" + month + "/" + year;
      return formattedTime;
    },
    timestampToDate: (value) => {
      let a = new Date(value);
      let year = a.getFullYear();
      let month = a.getMonth() + 1 < 10 ? "0" + (a.getMonth() + 1) : a.getMonth() + 1;
      let date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
      let formattedTime = date + "/" + month + "/" + year;
      return formattedTime;
    },
    timestampToDateTime: (value) => {
      let a = new Date(value);
      let year = a.getFullYear();
      let month = a.getMonth() + 1 < 10 ? "0" + (a.getMonth() + 1) : a.getMonth() + 1;
      let date = a.getDate() < 10 ? "0" + a.getDate() : a.getDate();
      let hour = a.getHours() < 10 ? "0" + a.getHours() : a.getHours();
      let min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
      let sec = a.getSeconds() < 10 ? "0" + a.getSeconds() : a.getSeconds();
      let formattedTime = date + "/" + month + "/" + year + " " + hour + ":" + min + ":" + sec;
      return formattedTime;
    },
  },

  components: {
    ModalAdd,
  },
};
</script>
<style scoped>
.container {
  margin-bottom: 5rem;
}

.data {
  margin-left: 7px;
}

.map {
  position: relative;
}

.map_name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid #cccccc;
  background: #fff;
  font-weight: bold;
}

::v-deep .table > tbody > tr > td.notes {
  max-width: 180px;
}
</style>
