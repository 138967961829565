<template>
  <div>
    <b-button class="mt-3 px-2 my-2" v-b-modal.modal-add variant="dark"><b-icon-person-plus-fill></b-icon-person-plus-fill> Add Player</b-button>
    <b-modal id="modal-add" ref="modal" size="lg" title="" @show="resetModal" @hidden="resetModal">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row class="my-2">
          <b-col sm="6">
            <b-form-group size="sm" class="my-2" label="Report date" label-for="report_date" :invalid-feedback="reportDateFeedback">
              <datepicker
                input-class="form-control form-control-sm"
                calendar-class="calendar"
                id="report_date"
                type="date"
                :invalid-feedback="reportDateFeedback"
                v-model="report.report_date"
                :state="reportDateState"
                format="dd/MM/yyyy"
                required
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group size="sm" class="my-2" label="Map played" label-for="map" :invalid-feedback="mapFeedback" :state="mapState">
              <v-select
                v-if="options"
                id="map"
                :clearable="false"
                class="style-chooser"
                :key="report.map"
                placeholder="Choose a map"
                :options="options"
                label="name"
                :reduce="(map) => map.id"
                v-model="report.map"
                ><span slot="no-options">Not an option</span>
                <template slot="option" slot-scope="option">
                  <div class="d-flex align-items-center">
                    <img width="70" height="40" class="map_image" :src="option.link" />
                    <span class="p-2">{{ option.name }}</span>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="d-flex align-items-center">
                    <img width="70" class="map_image" :src="option.link" />
                    <span class="p-2">{{ option.name }}</span>
                  </div>
                </template>
              </v-select>
            </b-form-group></b-col
          >
        </b-row>
        <b-form-group size="sm" class="my-2" label="Profile link" label-for="id-input" :invalid-feedback="idFeedback">
          <b-form-input
            size="sm"
            id="id-input"
            placeholder="https://steamcommunity.com/id/XXXXXXXX oppure https://steamcommunity.com/profiles/123456789..."
            type="url"
            v-model="report.id"
            required
            :state="idState"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="my-3" label="Cheat type" v-slot="{ ariaDescribedby }" :invalid-feedback="cheatsFeedback" :state="cheatsState">
          <b-form-checkbox-group id="cheatType" v-model="report.cheats" :options="cheatOptions" :aria-describedby="ariaDescribedby" :state="cheatsState" name="cheatTypes"></b-form-checkbox-group>
        </b-form-group>
        <b-form-group size="sm" class="my-2" label="Notes" label-for="notes" :invalid-feedback="noteFeedback">
          <b-form-input size="sm" id="notes" placeholder="Wall Hack e Aim Hack palesi" v-model="report.notes" :state="noteState" required></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer>
        <div>
          <b-button class="mx-2" variant="danger" size="sm" @click="handleCancel">
            Cancel
          </b-button>
          <b-button variant="dark" size="sm" @click="handleOk">
            Add Player
          </b-button>
        </div>
      </template>
      <template #modal-header="{ close }">
        <h4 class="mt-2"><b-icon-person-plus-fill></b-icon-person-plus-fill> Add Player</h4>
        <b-icon-x style="cursor: pointer" font-scale="1.3" @click="close()"></b-icon-x>
      </template>
    </b-modal>
  </div>
</template>
<script>
import SteamService from "../services/SteamService";
import ToastService from "../services/ToastService";
import Datepicker from "vuejs-datepicker";

export default {
  name: "ModalAdd",
  data: function() {
    return {
      tab: 0,
      idState: null,
      idFeedback: null,
      reportDateState: null,
      reportDateFeedback: null,
      mapState: null,
      mapFeedback: null,
      cheatsState: null,
      cheatsFeedback: null,
      noteState: null,
      noteFeedback: null,
      options: [],
      cheatOptions: [
        { value: 0, text: "Aim Hack" },
        { value: 1, text: "Wall Hack" },
        { value: 2, text: "Other Hacks" },
      ],
      report: {},
    };
  },

  mounted: function() {
    SteamService.getMaps()
      .then((res) => {
        this.options = res;
      })
      .catch((err) => {
        console.log(err);
      });
    this.report.report_date = new Date().toISOString().slice(0, 10);
  },

  computed: {},

  methods: {
    checkFormValidity() {
      let formState = true;
      this.reportDateState = true;
      this.mapState = true;
      this.idState = true;
      this.cheatsState = true;
      this.noteState = true;

      if (this.report.report_date == null) {
        this.reportDateFeedback = "Report date is required";
        this.reportDateState = false;
        formState = false;
      }
      if (this.report.map == null) {
        this.mapFeedback = "Map played is required";
        this.mapState = false;
        formState = false;
      }
      const re1 = new RegExp("^https?:\\/\\/(www.)?steamcommunity.com\\/id\\/[a-zA-Z0-9_-]+\\/?$", "g");
      const re2 = new RegExp("^https?:\\/\\/(www.)?steamcommunity.com\\/profiles\\/[0-9]{17}\\/?$", "g");
      if (this.report.id == null || (this.report.id.match(re1) == null && this.report.id.match(re2) == null)) {
        this.idFeedback = "Invalid Steam URL";
        this.idState = false;
        formState = false;
      }
      if (this.report.cheats == undefined || this.report.cheats.length === 0) {
        this.cheatsFeedback = "Cheats required";
        this.cheatsState = false;
        formState = false;
      }

      return formState;
    },
    resetModal() {
      this.clearReport();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    clearReport() {
      this.report.id = null;
      this.report.report_date = new Date().toISOString().slice(0, 10);
      this.report.cheats = [];
      this.report.notes = null;
      this.reportDateState = null;
      this.mapState = null;
      this.idState = null;
      this.cheatsState = null;
      this.noteState = null;
    },

    handleCancel() {
      this.clearReport();
      this.$bvModal.hide("modal-add");
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      } else {
        // Hide the modal manually
        this.$nextTick(() => {
          //CHANGE DATA TYPE
          this.report.report_date = new Date(this.report.report_date).toISOString().slice(0, 10);
          this.$bvModal.hide("modal-add");
          SteamService.addUser(this.report)
            .then((res) => {
              this.clearReport();
              if (res.ok == true) {
                ToastService.successToast("Player added successfully!");
                this.$emit("reload");
              } else ToastService.errorToast(res.error);
            })
            .catch(() => {
              ToastService.errorToast("Unexpected error, player not added!");
            });
        });
      }
    },
  },
  components: { Datepicker },
};
</script>
<style scoped>
.subtitle {
  font-size: 13px;
}

.title {
  font-size: 25px;
  font-weight: bold;
}
</style>
